import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import EventManagement from "../../Dashboard/EventManagement";
import Sidebar from "../../Common/Sidebar";
import { Navigation } from "../../Common/Navbar";
import SeriesManagement from "../../Dashboard/SeriesManagement";
import AllMarketDataComponent from "../../Dashboard/AllMarketData";
import AllMarketDataHorseRacing from "../../Dashboard/AllMarketDataHorseRacing";
import HorseRacingAddSeries from "../../Dashboard/HorseRacingAddSeries";
import HorseRacingAddEvents from "../../Dashboard/HorseRacingAddEvents";
import { useHistory } from "react-router-dom";
import EventManagementSoccer from "../../Dashboard/EventManagementSoccer";
import EventManagementHorseRacing from "../../Dashboard/EventManagementHorseRacing";
import BannerManagement from "../../Dashboard/manageBanners/BannerManagement";
import AddBanner from "../../Dashboard/manageBanners/addBanner";
import UpComingFixture from "../../Dashboard/upComingFixture";
import Announcements from "../../Dashboard/announcements";
import CreateAnnouncement from "../../Dashboard/announcements/createAnnouncement.js";

import { CloseButton, FormControl, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { IoHomeOutline, IoMenuOutline } from "react-icons/io5";
import {
  getUserDataAPI,
  searchEvent,
  searchEventSoccer,
  searchEventTennis,
  searchMarket,
  searchMarketSoccer,
  searchMarketTennis,
  updateEventCricket,
  updateEventSoccer,
  updateEventTennis,
} from "./Service";
import { IoSearchSharp } from "react-icons/io5";
import { useAllMarket } from "../../Context/AllMarketProvider";
import EventManagementTennis from "../../Dashboard/EventManagementTennis";
import AllMarketDataComponentTennis from "../../Dashboard/AllMarketDataTennis";
import AllMarketDataComponentSoccer from "../../Dashboard/AllMarketDataSoccer";

import CompetitionDetailsHorseRacing from "../../Dashboard/CompetitionDetailsHorseRacing";
import Settings from "../../Settings";
import Loader from "../Spinner";
import { FaHamburger } from "react-icons/fa";
import { useUser } from "../../Context/UserProvider";
import MatkaList from "../../Dashboard/matka/MatkaList.js";
import CreateMatka from "../../Dashboard/matka/CreateMatka.js";
import MarketListMatka from "../../Dashboard/matka/MarketList.js";

const DashboardLayout = () => {
  let history = useHistory();
  const location = useLocation();
  const { isDataLoading } = useUser();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { onSubmitAllMarketData } = useAllMarket();

  const pathTemp = location.pathname.split("/");
  const matchId = pathTemp[pathTemp.length - 1];
  const [marketNameQuery, setMarketNameQuery] = useState("");

  const [eventNameQuery, setEventNameQuery] = useState("");

  const handleChange = (e) => {
    //if enter key is pressed then search
    if (e.key === "Enter") {
      searchMarketHanlder();
    }
    setMarketNameQuery(e.target.value);
  };
  const handleEventChange = (e) => {
    setEventNameQuery(e.target.value);
  };

  const searchMarketHanlder = async () => {
    let res;
    if (location.pathname.includes("all-market/cricket")) {
      res = await searchMarket(matchId, marketNameQuery);
    } else if (location.pathname.includes("all-market/soccer")) {
      res = await searchMarketSoccer(matchId, marketNameQuery);
    } else if (location.pathname.includes("all-market/tennis")) {
      res = await searchMarketTennis(matchId, marketNameQuery);
    }

    if (res.code === 200) {
      onSubmitAllMarketData(res.response);
    }
  };
  const searchEventHanlder = async () => {
    let res;
    if (location.pathname.includes("event-management/cricket")) {
      res = await searchEvent(eventNameQuery);
    } else if (location.pathname.includes("event-management/soccer")) {
      res = await searchEventSoccer(eventNameQuery);
    } else if (location.pathname.includes("event-management/tennis")) {
      res = await searchEventTennis(eventNameQuery);
    }

    if (res.code === 200) {
      onSubmitAllMarketData(res.response);
    }
  };

  const getAccountDetails = async () => {
    const { response } = await getUserDataAPI(localStorage.getItem("userId"));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getAccountDetails();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className={`mobile-sidebar-${isMenuOpen ? "show" : "hide"}`}>
        <Sidebar
          sidebar={
            <div
              className="mobile-hide"
              onClick={() => setMenuOpen(!isMenuOpen)}
            >
              <CloseButton
                style={{
                  margin: "10px",
                }}
                color="white"
                size={"40px"}
              />
            </div>
          }
        />
      </div>
      <Sidebar />
      <div id="main">
        <div className="mobile-hide" onClick={() => setMenuOpen(!isMenuOpen)}>
          <IoMenuOutline
            style={{
              margin: "10px",
            }}
            color="white"
            size={"40px"}
          />
        </div>

        <Navigation />
        <div className="wrapper">
          <div class="page-heading d-flex">
            {location.pathname.includes("all-market") && (
              <InputGroup>
                <FormControl
                  placeholder="Search..."
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchMarketHanlder();
                    }
                  }}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={searchMarketHanlder}
                  style={{ cursor: "pointer" }}
                >
                  <IoSearchSharp />
                </InputGroup.Text>
              </InputGroup>
            )}
          </div>
          <div class="page-content">
            <div class="row" id="basic-table">
              <div class="col-12">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <div className="event_body">
                        {isDataLoading ? (
                          <Loader />
                        ) : (
                          <Switch>
                            <Route exact path="/dashboard/matka-market">
                              <MarketListMatka />
                            </Route>
                            <Route exact path="/dashboard/matka-market/:id">
                              <CreateMatka isEdit={true} isMatka={true} />
                            </Route>
                            <Route exact path="/dashboard/matka">
                              <MatkaList />
                            </Route>
                            <Route exact path="/dashboard/matka/create">
                              <CreateMatka />
                            </Route>
                            <Route exact path="/dashboard/matka/:id">
                              <CreateMatka isEdit={true} />
                            </Route>
                            <Route
                              exact
                              path="/dashboard/event-management/cricket"
                            >
                              <EventManagement />
                            </Route>
                            <Route exact path="/dashboard/event-management/t10">
                              <EventManagement />
                            </Route>
                            <Route
                              exact
                              path="/dashboard/event-management/tennis"
                            >
                              <EventManagementTennis />
                            </Route>
                            <Route
                              exact
                              path="/dashboard/event-management/soccer"
                            >
                              <EventManagementSoccer />
                            </Route>

                            <Route
                              exact
                              path="/dashboard/event-management/horse-racing"
                            >
                              <EventManagementHorseRacing />
                            </Route>

                            <Route
                              exact
                              path="/dashboard/horse-racing/add-series"
                            >
                              <HorseRacingAddSeries />
                            </Route>

                            <Route
                              exact
                              path="/dashboard/horse-racing/add-event/:seriesId"
                            >
                              <HorseRacingAddEvents />
                            </Route>

                            <Route
                              exact
                              path="/dashboard/all-market/cricket/:matchName/:eventId/:matchId"
                            >
                              <AllMarketDataComponent />
                            </Route>
                            <Route
                              exact
                              path="/dashboard/all-market/soccer/:matchName/:eventId/:matchId"
                            >
                              <AllMarketDataComponentSoccer />
                            </Route>
                            <Route
                              exact
                              path="/dashboard/all-market/tennis/:matchName/:eventId/:matchId"
                            >
                              <AllMarketDataComponentTennis />
                            </Route>
                            <Route path="/dashboard/horse-racing/competition-detail/:competitionName/:eventId">
                              <CompetitionDetailsHorseRacing />
                            </Route>
                            <Route
                              exact
                              path="/dashboard/all-market/horse-racing/:matchName/:eventId"
                            >
                              <AllMarketDataHorseRacing />
                            </Route>

                            <Route path="/dashboard/horse-racing/:seriesId/">
                              <AllMarketDataHorseRacing />
                            </Route>

                            <Route exact path="/dashboard/series-management">
                              <SeriesManagement />
                            </Route>
                            <Route exact path="/dashboard/settings">
                              <Settings />
                            </Route>

                            <Route exact path="/dashboard/banner-management">
                              <BannerManagement />
                            </Route>
                            <Route exact path="/dashboard/add-banner">
                              <AddBanner />
                            </Route>
                            <Route exact path="/dashboard/upcoming-fixture">
                              <UpComingFixture />
                            </Route>
                            <Route exact path="/dashboard/announcements">
                              <Announcements />
                            </Route>
                            <Route exact path="/dashboard/create-announcement">
                              <CreateAnnouncement />
                            </Route>
                          </Switch>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default DashboardLayout;

import React, { useEffect, useState } from "react";
import {
  getAllMatkaAPI,
  getMatkaMarketsAPI,
  settleMatkaMarketAPI,
} from "../../service/matka.service";
import { Modal, Row } from "react-bootstrap";
import moment from "moment";
import toast from "react-hot-toast";

export default function MarketListMatka() {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const getAllMatka = async () => {
    const { response } = await getMatkaMarketsAPI();
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    getAllMatka();
  }, []);
  const setResult = async () => {
    const { response, code, status } = await settleMatkaMarketAPI({
      marketId: modal.id,
      result: modal.result,
    });
    if (code == 200) {
      toast.success(status);

      setModal(false);
    } else {
      toast.error(response);
    }
    getAllMatka();
  };
  return (
    <div>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px",
        }}
      >
        <h2 style={{ width: "auto", marginRight: "auto" }}>Market List</h2>
        <button
          onClick={() => {
            window.location.href = "/dashboard/matka/create";
          }}
          style={{ width: "auto" }}
          className="btn btn-primary"
        >
          Create Matka
        </button>
      </Row>

      <table style={{ color: "white" }} className="table table-bordered light">
        <thead>
          <tr>
            <th>Matka Name</th>
            <th>Open Time</th>
            <th>Close Time</th>
            <th>Result Time</th>
            <th>Min bet</th>
            <th>Max bet</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item?.matka.name}</td>
              <td>{item?.matka.openTime}</td>
              <td>{item?.matka.closeTime}</td>
              <td>{item?.matka.resultTime}</td>
              <td>{item.minStack}</td>
              <td>{item.maxStack}</td>
              <td>
                <button
                  onClick={() => {
                    window.location.href = `/dashboard/matka-market/${item.id}`;
                  }}
                  className="btn btn-primary"
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    item?.result != null
                      ? toast.error("Coming soon")
                      : setModal(item);
                  }}
                  className={`btn ${
                    item?.result != null ? "btn-secondary" : "btn-primary"
                  }`}
                >
                  {item?.result != null ? "Rollback" : "Settle"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Settle Market</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              color: "white",
            }}
            className="form-group"
          >
            <label>Result</label>
            <input
              onChange={(e) => setModal({ ...modal, result: e.target.value })}
              className="form-control"
              type="text"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={() => setModal(false)}>
            Close
          </button>
          <button onClick={() => setResult()} className="btn btn-primary">
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

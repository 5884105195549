import { AXIOS_INSTANCE } from ".";
import { MATKA_BASE_URL } from "../utils/config";

export const getAllMatkaAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${MATKA_BASE_URL}/admin/matka`);
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatkaMarketsAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${MATKA_BASE_URL}/admin/matka-market`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getMatkaByIdAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${MATKA_BASE_URL}/admin/matka/${id}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const createMatkaAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${MATKA_BASE_URL}/admin/matka`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updateMatkaAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${MATKA_BASE_URL}/admin/matka/${payload?.id}`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const settleMatkaMarketAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${MATKA_BASE_URL}/settleMarket`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const editMatkaMarketAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${MATKA_BASE_URL}/admin/matka-market/${payload?.id}`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
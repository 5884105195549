import moment, { max } from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createMatkaAPI,
  editMatkaMarketAPI,
  getMatkaByIdAPI,
  getMatkaMarketsAPI,
  updateMatkaAPI,
} from "../../service/matka.service";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function CreateMatka({ isEdit, isMatka }) {
  const { id } = useParams();
  const [payload, setPayload] = useState({
    closeTime: `${moment().hour()}:${moment().minute()}:${moment().second()}`,
    isEnabled: true,
    maxStack: 0,
    minStack: 0,
    name: "",
    openTime: `${moment().hour()}:${moment().minute()}:${moment().second()}`,
    resultTime: `${moment().hour()}:${moment().minute()}:${moment().second()}`,
  });
  const handleSave = async () => {
    if (isEdit) {
      if (isMatka) {
        const { response, code } = await editMatkaMarketAPI({
          id: id,
          maxStack: payload.maxStack,
          minStack: payload.minStack,
          isEnabled: payload.isEnabled,
        });
        if (code === 200) {
          toast.success(response);
        } else {
          toast.error(response);
        }
      } else {
        const { response, code } = await updateMatkaAPI({
          ...payload,
          closeTime: payload.closeTime + ":00",
          openTime: payload.openTime + ":00",
          resultTime: payload.resultTime + ":00",
        });
        if (code === 200) {
          toast.success(response);
        } else {
          toast.error(response);
        }
      }
    } else {
      const { response, code } = await createMatkaAPI({
        ...payload,
        closeTime: payload.closeTime + ":00",
        openTime: payload.openTime + ":00",
        resultTime: payload.resultTime + ":00",
      });
      if (code === 200) {
        toast.success(response);
      } else {
        toast.error(response);
      }
    }
  };
  const getMatkaById = async (id) => {
    if (isMatka) {
      const { response } = await getMatkaMarketsAPI(id);
      if (response) {
        setPayload(
          response?.find((item) => item.id === parseInt(id))?.matka || payload
        );
      }
    } else {
      const { response } = await getMatkaByIdAPI(id);
      if (response) {
        setPayload(response);
      }
    }
  };
  useEffect(() => {
    if (isEdit) {
      getMatkaById(id);
    }
  }, [isEdit]);

  return (
    <div id="addBanner">
      <div class="page-heading">
        <h3>
          {isEdit ? (isMatka ? "Edit market" : "Edit Matka") : "Create Matka"}
        </h3>
      </div>
      <div class="page-content">
        <div class="row" id="basic-table">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <div class="row"></div>
                  <div className="event_body series_value_set">
                    <Row>
                      <Col md={6}>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Create Matka</Form.Label>
                            <Form.Control
                              type="text"
                              value={payload.name}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  name: e.target.value,
                                })
                              }
                              disabled={isMatka}
                              placeholder="Enter matka name"
                              name="min"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Open Time</Form.Label>
                            <form>
                              <input
                                value={payload.openTime
                                  .split(":")
                                  .slice(0, 2)
                                  .join(":")}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    openTime: e.target.value,
                                  })
                                }
                                disabled={isMatka}
                                type="time"
                              />
                            </form>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Close Time</Form.Label>
                            <form>
                              <input
                                disabled={isMatka}
                                value={payload.closeTime
                                  .split(":")
                                  .slice(0, 2)
                                  .join(":")}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    closeTime: e.target.value,
                                  })
                                }
                                type="time"
                              />
                            </form>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Result Time</Form.Label>
                            <form>
                              <input
                                disabled={isMatka}
                                value={payload.resultTime
                                  .split(":")
                                  .slice(0, 2)
                                  .join(":")}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    resultTime: e.target.value,
                                  });
                                }}
                                type="time"
                              />
                            </form>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Min Stack</Form.Label>
                            <input
                              type="number"
                              value={payload.minStack}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  minStack: e.target.value,
                                })
                              }
                              placeholder="Enter min stack"
                              name="min"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Max Stack</Form.Label>
                            <input
                              value={payload.maxStack}
                              type="number"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  maxStack: e.target.value,
                                })
                              }
                              placeholder="Enter max stack"
                              name="min"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Is Active</Form.Label>
                            <Form.Switch
                              value={payload.isEnabled}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  isEnabled: e.target.checked,
                                })
                              }
                              checked={payload.isEnabled}
                              type="checkbox"
                              label="Active"
                            />
                          </Form.Group>

                          <Button onClick={handleSave} variant="primary">
                            Save
                          </Button>
                        </Form>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
